import React, { useContext } from "react";
import { Container, Row, Col, Carousel, Button } from "react-bootstrap";
import { LanguageContext } from "../../context/LanguageContext";
import background1 from "../../assets/images/home/hero/background.png";
import background2 from "../../assets/images/home/hero/background2.png";
import "../../assets/styles/home/HeroSection.css";

function HeroSection() {
  const { language } = useContext(LanguageContext);

  const carouselItems = {
    en: [
      {
        title: "WE SUPPORT THE IT CONVERSION OF YOUR BUSINESS!",
        subtitle: "Transforming your ideas into reality",
        image: "https://placehold.co/600x400?text=Slide+1",
        background: background1,
      },
      {
        title: "FPOS RELEASE!",
        subtitle: "We provide the best POS system in the market!",
        image: "https://placehold.co/600x400?text=Slide+2",
        button: {
          text: "Learn More",
          link: "https://fjtsolutions.com/app/fpos/th",
        },
        background: background2,
      },
    ],
    jp: [
      {
        title: "業務のIT化をサポートいたします！",
        subtitle: "あなたのアイデアを現実に変えます",
        image: "https://placehold.co/600x400?text=スライド+1",
        background: background1,
      },
      {
        title: "FPOSリリース！",
        subtitle: "コストパフォーマンス最強のPOSシステム",
        image: "https://placehold.co/600x400?text=スライド+2",
        button: {
          text: "詳細を見る",
          link: "https://fjtsolutions.com/app/fpos/ja",
        },
        background: background2,
      },
    ],
    th: [
      {
        title: "เราสนับสนุนการปรับเปลี่ยนงานให้เป็นระบบ IT!",
        subtitle: "เราจะเปลี่ยนความคิดของคุณให้กลายเป็นความจริง",
        image: "https://placehold.co/600x400?text=スライド+1",
        background: background1,
      },
      {
        title: "เปิดตัว FPOS แล้ว!",
        subtitle: "ระบบ POS ที่มีความคุ้มค่าที่สุด",
        image: "https://placehold.co/600x400?text=スライド+2",
        button: {
          text: "ดูรายละเอียด",
          link: "https://fjtsolutions.com/app/fpos/ja",
        },
        background: background2,
      },
    ],
  };

  return (
    <section className="hero-section">
      <Carousel controls={false} indicators={false} interval={5000}>
        {carouselItems[language].map((item, index) => (
          <Carousel.Item key={index}>
            <div
              className="carousel-background"
              style={{
                backgroundImage: `url(${item.background})`,
              }}
            >
              <Container>
                <Row className="align-items-center">
                  <Col md={6}>
                    <h1 className="hero-title">{item.title}</h1>
                    <p className="hero-subtitle">{item.subtitle}</p>
                    {item.button && (
                      <Button
                        variant="primary"
                        href={item.button.link}
                        className="hero-button"
                      >
                        {item.button.text}
                      </Button>
                    )}
                  </Col>
                  {/* <Col md={6}>
                    <img
                      src={item.image}
                      alt={item.title}
                      className="img-fluid hero-image"
                    />
                  </Col> */}
                </Row>
              </Container>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  );
}

export default HeroSection;
