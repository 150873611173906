import React, { useContext, useState } from "react";
import { Container, Row, Col, Card, Modal, Button } from "react-bootstrap";
import "../../assets/styles/home/ServiceSection.css";
import mobileImage from "../../assets/images/home/service/iphone_small.jpg";
import pcImage from "../../assets/images/home/service/pc_small.jpg";
import hybridImage from "../../assets/images/home/service/hybrid_small.jpg";
import { LanguageContext } from "../../context/LanguageContext";

function ServicesSection() {
  const { language } = useContext(LanguageContext);
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    description: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = (title, description) => {
    setModalContent({ title, description });
    setShow(true);
  };

  const title = {
    en: "Our Services",
    jp: "提供サービス",
    th: "บริการที่เราให้",
  };

  const mobileApp = {
    en: "Mobile App Development",
    jp: "モバイルアプリ開発",
    th: "การพัฒนาแอปพลิเคชันมือถือ",
  };

  const webDev = {
    en: "Web Development",
    jp: "Web開発",
    th: "การพัฒนาเว็บไซต์",
  };

  const hybridApp = {
    en: "Hybrid App Development",
    jp: "ハイブリッドアプリ開発",
    th: "การพัฒนาแอปพลิเคชันแบบไฮบริด",
  };

  const mobileAppDescription = {
    en: "We develop apps for smartphones and tablets. We can create apps for business, personal use, and more.",
    jp: "スマートフォン、タブレット向けのアプリを作成いたします。業務用/ビジネス向けはもちろん個人運営用のアプリのご提案、開発をいたします。",
    th: "เราพัฒนาแอปพลิเคชันสำหรับสมาร์ทโฟนและแท็บเล็ต ไม่ว่าจะเป็นแอปพลิเคชันสำหรับธุรกิจหรือการใช้งานส่วนบุคคล เราพร้อมที่จะเสนอแนวคิดและพัฒนาแอปพลิเคชันให้กับคุณ",
  };

  const mobileAppDescriptionDetail = {
    en: `Currently, our website offers a feature that displays detailed information in an easy-to-understand text format with just a single click. This feature allows users to easily access the necessary information, enhancing their browsing experience.<br /><br />
         Furthermore, we provide app development services for smartphones and tablets. We offer proposals and development for both business and personal apps tailored to our clients' needs.<br /><br />
         Our app development services have the following features:<br /><br />
         Customizable Solutions: We provide apps optimized for your business model and operational policies.<br />
         User-Friendly Design: We design intuitive and easy-to-use interfaces to maximize user experience.<br />
         Advanced Functionality: We utilize the latest technologies to develop high-performance apps.<br />
         Support System: We offer continuous support after development to assist with the maintenance and management of your apps.<br /><br />
         We provide comprehensive services from the initial planning stages to post-release support, helping to realize our clients' visions. To accelerate your business growth, please take advantage of our app development services.`,
    jp: `現在、当社のWEBサイトでは、クリック一つで詳細内容をわかりやすい文章で表示する機能を提供しています。この機能により、ユーザーは必要な情報に簡単にアクセスでき、閲覧体験が向上します。<br /><br />
         さらに、当社はスマートフォンやタブレット向けのアプリ開発サービスを提供しています。業務用やビジネス向けのアプリはもちろん、個人運営用のアプリもお客様のニーズに合わせてご提案・開発いたします。<br /><br />
         当社のアプリ開発サービスは、以下の特徴を持っています：<br /><br />
         カスタマイズ可能なソリューション: お客様のビジネスモデルや運営方針に最適化されたアプリを提供します。<br />
         ユーザーフレンドリーなデザイン: 直感的で使いやすいインターフェースを設計し、ユーザーエクスペリエンスを最大化します。<br />
         高度な機能性: 最新の技術を活用し、パフォーマンスの高いアプリを開発します。<br />
         サポート体制: 開発後も継続的なサポートを提供し、アプリの維持・管理をお手伝いいたします。<br /><br />
         私たちは、アプリの開発だけでなく、初期の企画段階からリリース後のサポートまで、一貫したサービスを提供することで、お客様のビジョンを実現するお手伝いをいたします。ビジネスの成長を加速させるために、ぜひ私たちのアプリ開発サービスをご活用ください。`,
    th: `ปัจจุบัน เว็บไซต์ของเรามีฟังก์ชันที่ช่วยแสดงรายละเอียดเนื้อหาในรูปแบบข้อความที่เข้าใจง่ายเพียงคลิกเดียว ฟังก์ชันนี้ทำให้ผู้ใช้สามารถเข้าถึงข้อมูลที่ต้องการได้อย่างง่ายดาย และปรับปรุงประสบการณ์การเข้าชมเว็บไซต์<br /><br />
นอกจากนี้เรายังมีบริการพัฒนาแอปพลิเคชันสำหรับสมาร์ทโฟนและแท็บเล็ต ไม่ว่าจะเป็นแอปพลิเคชันสำหรับธุรกิจหรือการใช้งานส่วนบุคคล เราพร้อมที่จะเสนอแนวคิดและพัฒนาแอปให้ตรงตามความต้องการของลูกค้า<br /><br />
บริการพัฒนาแอปพลิเคชันของเรามีลักษณะเด่นดังนี้:<br /><br />
โซลูชันที่ปรับแต่งได้: เราให้บริการแอปที่ปรับให้เหมาะสมกับโมเดลธุรกิจและนโยบายการดำเนินงานของคุณ<br />
การออกแบบที่เป็นมิตรกับผู้ใช้: เราออกแบบอินเทอร์เฟซที่ใช้งานง่ายและเป็นธรรมชาติ เพื่อเพิ่มประสบการณ์การใช้งานสูงสุด<br />
ฟังก์ชันที่มีประสิทธิภาพสูง: เราใช้เทคโนโลยีล่าสุดในการพัฒนาแอปพลิเคชันที่มีประสิทธิภาพสูง<br />
การสนับสนุน: เราให้บริการสนับสนุนอย่างต่อเนื่องหลังจากการพัฒนา เพื่อช่วยในการบำรุงรักษาและจัดการแอปพลิเคชัน<br /><br />
เราไม่เพียงแต่พัฒนาแอปเท่านั้น แต่ยังให้บริการครบวงจรตั้งแต่การวางแผนเริ่มต้นจนถึงการสนับสนุนหลังจากการเปิดตัว เพื่อช่วยให้วิสัยทัศน์ของคุณเป็นจริง ใช้บริการพัฒนาแอปของเราเพื่อเร่งการเติบโตทางธุรกิจของคุณ
`,
  };

  const webDevDescription = {
    en: "We build secure web applications, e-commerce sites, and websites using frameworks.",
    jp: "フレームワークを利用した安全なWebアプリケーション、ECサイト、Webサイトの構築をいたします。",
    th: "เราพัฒนาแอปพลิเคชันเว็บที่ปลอดภัย เว็บไซต์อีคอมเมิร์ซ และเว็บไซต์ โดยใช้เฟรมเวิร์กที่ทันสมัย",
  };

  const webDevDescriptionDetail = {
    en: `Our web development services encompass a wide range of solutions to meet your business needs. We specialize in creating secure web applications, robust e-commerce sites, and visually appealing websites using modern frameworks and technologies.<br /><br />
         We understand the importance of security in web development, and our team is dedicated to implementing best practices to ensure your data is protected. Our e-commerce solutions are designed to provide seamless shopping experiences, with features such as secure payment gateways, user-friendly interfaces, and efficient product management systems.<br /><br />
         Our web development services also include:<br /><br />
         Custom Web Applications: Tailored solutions to fit your unique business requirements.<br />
         Responsive Design: Ensuring your website looks great on all devices, from desktops to smartphones.<br />
         SEO Optimization: Implementing strategies to improve your website's visibility in search engine results.<br />
         Ongoing Maintenance: Providing continuous support and updates to keep your website running smoothly.<br /><br />
         Partner with us to leverage our expertise in web development and achieve your digital goals.`,
    jp: `当社のWeb開発サービスは、お客様のビジネスニーズに応じた幅広いソリューションを提供します。安全なWebアプリケーション、堅牢なECサイト、視覚的に魅力的なWebサイトを最新のフレームワークと技術を使用して構築します。<br /><br />
         Web開発におけるセキュリティの重要性を理解しており、当社のチームはデータ保護のためのベストプラクティスを実装することに専念しています。ECソリューションは、安全な支払いゲートウェイ、ユーザーフレンドリーなインターフェース、効率的な製品管理システムなど、シームレスなショッピング体験を提供するように設計されています。<br /><br />
         当社のWeb開発サービスには以下が含まれます：<br /><br />
         カスタムWebアプリケーション: お客様のユニークなビジネス要件に合わせたソリューション。<br />
         レスポンシブデザイン: デスクトップからスマートフォンまで、すべてのデバイスで見栄えの良いWebサイトを実現。<br />
         SEO最適化: 検索エンジンの結果でWebサイトの視認性を向上させるための戦略の実装。<br />
         継続的なメンテナンス: Webサイトの円滑な運用を維持するためのサポートとアップデートの提供。<br /><br />
         当社のWeb開発の専門知識を活用し、デジタル目標を達成するためのパートナーとしてぜひご利用ください。`,
    th: `ปัจจุบัน เว็บไซต์ของเรามีฟังก์ชันที่ช่วยแสดงรายละเอียดเนื้อหาในรูปแบบข้อความที่เข้าใจง่ายเพียงคลิกเดียว ฟังก์ชันนี้ทำให้ผู้ใช้สามารถเข้าถึงข้อมูลที่ต้องการได้อย่างง่ายดาย และปรับปรุงประสบการณ์การเข้าชมเว็บไซต์<br /><br />
นอกจากนี้เรายังมีบริการพัฒนาแอปพลิเคชันสำหรับสมาร์ทโฟนและแท็บเล็ต ไม่ว่าจะเป็นแอปพลิเคชันสำหรับธุรกิจหรือการใช้งานส่วนบุคคล เราพร้อมที่จะเสนอแนวคิดและพัฒนาแอปให้ตรงตามความต้องการของลูกค้า<br /><br />
บริการพัฒนาแอปพลิเคชันของเรามีลักษณะเด่นดังนี้:<br /><br />
โซลูชันที่ปรับแต่งได้: เราให้บริการแอปที่ปรับให้เหมาะสมกับโมเดลธุรกิจและนโยบายการดำเนินงานของคุณ<br />
การออกแบบที่เป็นมิตรกับผู้ใช้: เราออกแบบอินเทอร์เฟซที่ใช้งานง่ายและเป็นธรรมชาติ เพื่อเพิ่มประสบการณ์การใช้งานสูงสุด<br />
ฟังก์ชันที่มีประสิทธิภาพสูง: เราใช้เทคโนโลยีล่าสุดในการพัฒนาแอปพลิเคชันที่มีประสิทธิภาพสูง<br />
การสนับสนุน: เราให้บริการสนับสนุนอย่างต่อเนื่องหลังจากการพัฒนา เพื่อช่วยในการบำรุงรักษาและจัดการแอปพลิเคชัน<br /><br />
เราไม่เพียงแต่พัฒนาแอปเท่านั้น แต่ยังให้บริการครบวงจรตั้งแต่การวางแผนเริ่มต้นจนถึงการสนับสนุนหลังจากการเปิดตัว เพื่อช่วยให้วิสัยทัศน์ของคุณเป็นจริง ใช้บริการพัฒนาแอปของเราเพื่อเร่งการเติบโตทางธุรกิจของคุณ
`,
  };

  const hybridAppDescription = {
    en: "We develop apps that work on both websites and smartphones. We can provide the best solution for your needs.",
    jp: "Webサイト、スマートフォン共に対応したアプリを用途、目的に応じて開発。最適なご提案をいたします。",
    th: "เราพัฒนาแอปพลิเคชันที่รองรับทั้งเว็บไซต์และสมาร์ทโฟนตามการใช้งานและวัตถุประสงค์ของคุณ พร้อมนำเสนอแนวทางที่เหมาะสมที่สุด",
  };

  const hybridAppDescriptionDetail = {
    en: `Our hybrid app development services offer the best of both worlds, providing solutions that work seamlessly across both web and mobile platforms. Whether you need an app for business, personal use, or any other purpose, we can deliver a solution that meets your needs.<br /><br />
         Hybrid apps are designed to provide a consistent user experience across different devices and operating systems. By leveraging modern technologies, we ensure that your app performs well, whether it is accessed via a browser or installed on a smartphone.<br /><br />
         Our hybrid app development services include:<br /><br />
         Cross-Platform Compatibility: Ensuring your app works seamlessly on both iOS and Android devices.<br />
         Efficient Development: Reducing development time and cost by using a single codebase for both web and mobile apps.<br />
         High Performance: Utilizing advanced frameworks to deliver fast and responsive applications.<br />
         Continuous Support: Providing ongoing maintenance and updates to keep your app running smoothly.<br /><br />
         Let us help you bring your vision to life with our hybrid app development expertise. Reach out to us for the best solutions tailored to your specific needs.`,
    jp: `当社のハイブリッドアプリ開発サービスは、Webとモバイルプラットフォームの両方でシームレスに動作するソリューションを提供します。ビジネス、個人利用、その他の目的に関わらず、お客様のニーズに合ったソリューションを提供いたします。<br /><br />
         ハイブリッドアプリは、異なるデバイスやオペレーティングシステム間で一貫したユーザーエクスペリエンスを提供するように設計されています。最新の技術を活用することで、ブラウザ経由でもスマートフォンにインストールしても、アプリが良好に動作するように保証します。<br /><br />
         当社のハイブリッドアプリ開発サービスには以下が含まれます：<br /><br />
         クロスプラットフォーム互換性: iOSおよびAndroidデバイスの両方でシームレスに動作するアプリを保証。<br />
         効率的な開発: Webおよびモバイルアプリの両方に単一のコードベースを使用することで、開発時間とコストを削減。<br />
         高パフォーマンス: 高度なフレームワークを利用して、迅速かつ応答性の高いアプリケーションを提供。<br />
         継続的なサポート: アプリの円滑な運用を維持するためのメンテナンスとアップデートを提供。<br /><br />
         当社のハイブリッドアプリ開発の専門知識を活用して、お客様のビジョンを実現するお手伝いをいたします。特定のニーズに合わせた最適なソリューションを提供するために、ぜひお問い合わせください。`,
    th: `บริการพัฒนาแอปพลิเคชันแบบไฮบริดของเรามอบโซลูชันที่ทำงานได้อย่างราบรื่นทั้งบนแพลตฟอร์มเว็บและมือถือ ไม่ว่าคุณจะใช้สำหรับธุรกิจ การใช้งานส่วนบุคคล หรือวัตถุประสงค์อื่นๆ เราจะนำเสนอทางออกที่ตรงกับความต้องการของคุณ<br /><br />
แอปพลิเคชันไฮบริดได้รับการออกแบบเพื่อมอบประสบการณ์การใช้งานที่สอดคล้องกันระหว่างอุปกรณ์และระบบปฏิบัติการต่างๆ ด้วยการใช้เทคโนโลยีที่ทันสมัย เรารับรองว่าแอปจะทำงานได้อย่างมีประสิทธิภาพ ไม่ว่าจะเข้าถึงผ่านเบราว์เซอร์หรือทำการติดตั้งบนสมาร์ทโฟน<br /><br />
บริการพัฒนาแอปพลิเคชันแบบไฮบริดของเราประกอบด้วย:<br /><br />
ความเข้ากันได้ข้ามแพลตฟอร์ม: รับประกันการทำงานที่ราบรื่นทั้งบนอุปกรณ์ iOS และ Android<br />
การพัฒนาที่มีประสิทธิภาพ: ลดเวลาและค่าใช้จ่ายในการพัฒนาโดยใช้โค้ดเบสเดียวสำหรับทั้งแอปบนเว็บและมือถือ<br />
ประสิทธิภาพสูง: ใช้เฟรมเวิร์กล้ำสมัยเพื่อมอบแอปที่ตอบสนองรวดเร็วและมีประสิทธิภาพสูง<br />
การสนับสนุนต่อเนื่อง: ให้บริการบำรุงรักษาและอัปเดตเพื่อให้การทำงานของแอปเป็นไปอย่างราบรื่น<br /><br />
ใช้ความเชี่ยวชาญในการพัฒนาแอปพลิเคชันแบบไฮบริดของเราเพื่อช่วยให้คุณบรรลุวิสัยทัศน์ของคุณ ติดต่อเราเพื่อรับโซลูชันที่ดีที่สุดตามความต้องการของคุณ
`,
  };

  return (
    <section className="services-section">
      <Container>
        <h2 className="section-title">{title[language]}</h2>
        <Row>
          <Col md={4} className="d-flex">
            <Card
              className="service-card flex-fill"
              onClick={() =>
                handleShow(
                  mobileApp[language],
                  mobileAppDescriptionDetail[language]
                )
              }
            >
              <Card.Img variant="top" src={mobileImage} />
              <Card.Body className="d-flex flex-column">
                <Card.Title>{mobileApp[language]}</Card.Title>
                <Card.Text className="flex-grow-1">
                  {mobileAppDescription[language]}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="d-flex">
            <Card
              className="service-card flex-fill"
              onClick={() =>
                handleShow(webDev[language], webDevDescriptionDetail[language])
              }
            >
              <Card.Img variant="top" src={pcImage} />
              <Card.Body className="d-flex flex-column">
                <Card.Title>{webDev[language]}</Card.Title>
                <Card.Text className="flex-grow-1">
                  {webDevDescription[language]}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="d-flex">
            <Card
              className="service-card flex-fill"
              onClick={() =>
                handleShow(
                  hybridApp[language],
                  hybridAppDescriptionDetail[language]
                )
              }
            >
              <Card.Img variant="top" src={hybridImage} />
              <Card.Body className="d-flex flex-column">
                <Card.Title>{hybridApp[language]}</Card.Title>
                <Card.Text className="flex-grow-1">
                  {hybridAppDescription[language]}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalContent.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          dangerouslySetInnerHTML={{ __html: modalContent.description }}
        />
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}

export default ServicesSection;
