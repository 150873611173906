import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Dropdown } from "react-bootstrap";
import { LanguageContext } from "../../context/LanguageContext";
import { FaGlobe } from "react-icons/fa";
import "../../assets/styles/Header.css";
import logoImage from "../../assets/images/header/logo.png";

function Header() {
  const { language, switchLanguage, languages } = useContext(LanguageContext);

  const home = {
    en: "Home",
    jp: "ホーム",
    th: "หน้าแรก",
  };

  const about = {
    en: "About",
    jp: "会社概要",
    th: "ข้อมูลบริษัท",
  };

  const contact = {
    en: "Contact",
    jp: "お問い合わせ",
    th: "ติดต่อเรา",
  };

  return (
    <header className="header">
      <Navbar expand="lg" className="navbar">
        <Navbar.Brand className="navbar-logo">
          <Link to="/">
            <img src={logoImage} alt="" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto navbar-links">
            <Nav.Link as={Link} to="/" className="nav-link">
              {home[language]}
            </Nav.Link>
            <Nav.Link as={Link} to="/about" className="nav-link">
              {about[language]}
            </Nav.Link>
            <Nav.Link as={Link} to="/contact" className="nav-link">
              {contact[language]}
            </Nav.Link>
          </Nav>
          <Dropdown className="language-switcher">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <FaGlobe />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {Object.keys(languages).map((lang) => (
                <Dropdown.Item
                  key={lang}
                  onClick={() => switchLanguage(lang)}
                  active={language === lang}
                >
                  {languages[lang]}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}

export default Header;
