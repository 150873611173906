import React, { useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../context/LanguageContext";

function ActionSection() {
  const { language } = useContext(LanguageContext);

  const mainMessage = {
    en: "Ready to Start Your Project?",
    jp: "改革を始めましょう！",
    th: "เริ่มการปฏิรูปกันเถอะ!",
  };

  const subMessage = {
    en: "Contact us today to discuss your ideas and get a free quote.",
    jp: "お問い合わせいただければ、無料見積もりを提供いたします。",
    th: "หากคุณติดต่อเรา เราจะให้ใบเสนอราคาฟรี",
  };

  const buttonMessage = {
    en: "Contact Us",
    jp: "お問い合わせ",
    th: "ติดต่อเรา",
  };

  return (
    <section className="cta-section">
      <Container>
        <Row className="align-items-center">
          <Col md={9}>
            <h2 className="cta-title">{mainMessage[language]}</h2>
            <p className="cta-subtitle">{subMessage[language]}</p>
          </Col>
          <Col md={3}>
            <Button as={Link} to="/contact" variant="success" size="lg">
              {buttonMessage[language]}
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ActionSection;
