import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../assets/styles/home/NewsSection.css";
import { LanguageContext } from "../../context/LanguageContext";
import fjttImage from "../../assets/images/news/fjtt/top.jpg";
import attendanceImage from "../../assets/images/news/attendance/top.png";
import fjtImage from "../../assets/images/news/fjt/top.jpg";

function NewsSection() {
  const { language } = useContext(LanguageContext);

  const title = {
    en: "Latest News",
    jp: "最新ニュース",
    th: "ข่าวสารล่าสุด",
  };

  const newsItems = [
    {
      id: 1,
      title: {
        en: "New Company Info",
        jp: "会社設立のお知らせ",
        th: "ประกาศการก่อตั้งบริษัท",
      },
      excerpt: {
        en: "We have established FJT SOLUTIONS, Inc.",
        jp: "FJTソリューションズ合同会社設立を設立いたしました。",
        th: "เราได้ก่อตั้งบริษัท FJT Solutions จำกัดขึ้นแล้ว",
      },
      date: "2022-01-04",
      image: fjtImage, // 画像のURLを追加
    },
    {
      id: 2,
      title: {
        en: "New Company Info",
        jp: "会社設立のお知らせ",
        th: "ประกาศการก่อตั้งบริษัท",
      },
      excerpt: {
        en: "We have established FJT SOLUTIONS (THAILAND) CO.,LTD.",
        jp: "FJT SOLUTIONS (THAILAND) CO.,LTD. を設立いたしました。",
        th: "เราได้ก่อตั้งบริษัท FJT SOLUTIONS (THAILAND) CO.,LTD. ขึ้นแล้ว",
      },
      date: "2023-06-20",
      image: fjttImage, // 画像のURLを追加
    },
  ];

  return (
    <section className="news-section">
      <Container>
        <h2 className="section-title">{title[language]}</h2>
        <Row>
          {newsItems.map((news) => (
            <Col md={4} key={news.id} className="news-item">
              <div className="news-content">
                <img
                  src={news.image}
                  alt={news.title[language]}
                  className="news-image"
                />
                <h3>
                  <Link to={`/news/${news.id}`}>{news.title[language]}</Link>
                </h3>
                <p>{news.excerpt[language]}</p>
                <small>{news.date}</small>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default NewsSection;
