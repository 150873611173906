import React, { useContext } from "react";
import { Container, Row, Col, Table, Breadcrumb } from "react-bootstrap";
import globalIllustration from "../../assets/images/about/global-illustration.png";
import declarationImageJp from "../../assets/images/about/declaration_jp.png";
import declarationImageEn from "../../assets/images/about/declaration_en.png";
import declarationImageTh from "../../assets/images/about/declaration_th.png";
import "../../assets/styles/about/AboutPage.css";
import { Link, useLocation } from "react-router-dom";
import { LanguageContext } from "../../context/LanguageContext";

function AboutPage() {
  const location = useLocation();
  const { language } = useContext(LanguageContext);

  const home = {
    en: "Home",
    jp: "ホーム",
    th: "หน้าแรก",
  };

  const about = {
    en: "About Us",
    jp: "会社概要",
    th: "ข้อมูลบริษัท",
  };

  const image = {
    en: declarationImageEn,
    jp: declarationImageJp,
    th: declarationImageTh,
  };

  return (
    <Container className="about-page">
      <Row className="justify-content-center">
        <Col md={10}>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
              {home[language]}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{about[language]}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={10} className="text-center">
          {/* <h1 className="page-title">会社概要</h1> */}
          <img
            src={image[language]}
            alt="Declaration"
            width={800}
            className="img-fluid global-illustration"
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={10}>
          <h2 className="company-title">FJTソリューションズ合同会社</h2>
          <Table bordered className="company-table">
            <tbody>
              <tr>
                <th>社名</th>
                <td>FJTソリューションズ合同会社 (FJT SOLUTIONS, Inc.)</td>
              </tr>
              <tr>
                <th>代表者</th>
                <td>藤田研史</td>
              </tr>
              <tr>
                <th>所在地</th>
                <td>〒166-0002 東京都杉並区高円寺北3-38-4</td>
              </tr>
              <tr>
                <th>資本金</th>
                <td>2,000,000円</td>
              </tr>
              <tr>
                <th>設立日</th>
                <td>2022年1月4日</td>
              </tr>
              <tr>
                <th>主たる事業内容</th>
                <td>
                  インターネットを利用した各種情報提供サービス
                  <br />
                  Webサイトの企画、制作、販売、運営及び管理
                  <br />
                  EC（電子商取引）サイトの企画、制作、販売、運営及び管理
                  <br />
                  コンピュータシステムの企画、開発、販売及び保守に関する業務
                  <br />
                  各種アプリケーションソフトの企画、制作、販売、運営及び管理
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={10}>
          <h2 className="company-title">FJT SOLUTIONS (THAILAND) CO., LTD.</h2>
          <Table bordered className="company-table">
            <tbody>
              <tr>
                <th>社名</th>
                <td>FJT SOLUTIONS (THAILAND) CO., LTD.</td>
              </tr>
              <tr>
                <th>代表者</th>
                <td>KENJI FUJITA</td>
              </tr>
              <tr>
                <th>所在地</th>
                <td>
                  ITF Silom Palace Building, 160/303, 17th Floor, Silom Road,
                  Suriyawong, Bangrak, Bangkok 10500
                </td>
              </tr>
              <tr>
                <th>資本金</th>
                <td>2,000,000 Baht</td>
              </tr>
              <tr>
                <th>設立日</th>
                <td>2023年3月</td>
              </tr>
              <tr>
                <th>主たる事業内容</th>
                <td>
                  ประกอบกิจการให้บริการทำทางด้านการจัดทำแผนทางการตลาด
                  การจัดทำและพัฒนาเว็บไซต์ EC การรักษาความปลอดภัยทางคอมพิวเตอร์
                  การทำงานด้านซอฟต์แวร์ และอื่นๆที่เกี่ยวข้อง
                  <br />
                  ประกอบกิจการให้บริการในด้านการวางระบบ การพัฒนา ระบบคอมพิวเตอร์
                  ซอฟต์แวร์ และอื่นๆที่เกี่ยวข้อง
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default AboutPage;
