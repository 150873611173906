import React, { useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../../assets/styles/home/PortfolioSection.css";
import trackImage from "../../assets/images/home/portfolio/tracking.png";
import trackingAnimation from "../../assets/images/home/portfolio/tracking.json";
import posImage from "../../assets/images/home/portfolio/pos.jpg";
import attendanceImage from "../../assets/images/home/portfolio/attendance.png";
import { LanguageContext } from "../../context/LanguageContext";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";

function PortfolioSection() {
  const { language } = useContext(LanguageContext);

  const trackingOptions = {
    loop: true,
    autoplay: true,
    animationData: trackingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const title = {
    en: "Our Portfolio",
    jp: "提供サービス",
    th: "บริการที่เราให้",
  };

  const trackApp = {
    en: "Location Tracking App",
    jp: "位置情報追跡アプリ",
    th: "แอปติดตามตำแหน่ง",
  };

  const posApp = {
    en: "POS System",
    jp: "POSシステム",
    th: "ระบบ POS",
  };

  const showDetail = {
    en: "Show Detail",
    jp: "詳細を見る",
    th: "ดูรายละเอียด",
  };

  const fposUrl = {
    en: "https://fjtsolutions.com/app/fpos/th",
    jp: "https://fjtsolutions.com/app/fpos/ja",
    th: "https://fjtsolutions.com/app/fpos/th",
  };

  const attendanceApp = {
    en: "Attendance App",
    jp: "出勤簿アプリ",
    th: "แอปบันทึกการเข้างาน",
  };

  const trackAppDescription = {
    en: "This app is compatible with smartphones such as Android and iPhone, making your daily life more convenient and safe. It provides location tracking and movement history recording, allowing you to easily check the location of family, friends, and vehicles. It also has security features to support safety.",
    jp: "AndroidやiPhone等のスマホに対応し、日常を便利かつ安全にするツールです。位置追跡や移動履歴の記録を提供し、家族や友人、車両の位置を簡単に確認できます。セキュリティ機能も搭載して、安全をサポートいたします。",
    th: "รองรับสมาร์ทโฟนทั้ง Android และ iPhone เป็นเครื่องมือที่ช่วยให้ชีวิตประจำวันสะดวกและปลอดภัยยิ่งขึ้น โดยมีฟังก์ชันติดตามตำแหน่งและบันทึกประวัติการเคลื่อนไหว ช่วยให้คุณสามารถตรวจสอบตำแหน่งของครอบครัว เพื่อน หรือยานพาหนะได้อย่างง่ายดาย นอกจากนี้ยังมีฟังก์ชันด้านความปลอดภัยเพื่อสนับสนุนความปลอดภัยของคุณ",
  };

  const posAppDescription = {
    en: "This app is a useful tool for store management that can be started with just one tablet such as an iPad. By introducing this app, store management efficiency and response to busy periods are made easy, contributing to improved customer service.",
    jp: "本アプリはiPad等のタブレット1台から始められる店舗経営にとって有用なツールです。このアプリを導入することで、店舗運営の効率化や繁忙期の対応が容易になり、お客様へのサービス向上に寄与します。",
    th: "แอปนี้เป็นเครื่องมือที่มีประโยชน์สำหรับการบริหารจัดการร้านค้า ซึ่งสามารถเริ่มต้นใช้งานได้จากแท็บเล็ตเครื่องเดียว เช่น iPad การติดตั้งแอปนี้จะช่วยให้การดำเนินงานของร้านมีประสิทธิภาพมากขึ้น และจัดการกับช่วงเวลาที่มีลูกค้าจำนวนมากได้ง่ายขึ้น ซึ่งจะส่งผลให้บริการลูกค้าดีขึ้น",
  };

  const attendanceAppDescription = {
    en: "This smartphone app supports the efficiency of field work. It solves paper work reports and allows you to easily report on your smartphone (iOS, Android) and attach photos.",
    jp: "スマートフォンアプリで現場作業の効率化を支援します。紙の作業報告書を解決し、スマホ（iOS, Android）で簡単に報告でき、写真も添付可能です。",
    th: "แอปสมาร์ทโฟนช่วยเพิ่มประสิทธิภาพการทำงานในสถานที่จริง ขจัดความยุ่งยากของรายงานการทำงานแบบกระดาษ คุณสามารถรายงานได้ง่ายๆ ผ่านสมาร์ทโฟน (iOS, Android) และแนบรูปภาพได้ด้วย",
  };

  return (
    <section className="portfolio-section">
      <Container>
        <h2 className="section-title">{title[language]}</h2>

        <Row className="portfolio-item">
          <Col md={6}>
            {/* <img src={trackImage} alt="Project 1" className="img-fluid" /> */}
            <Lottie options={trackingOptions} height={300} width={300} />
          </Col>
          <Col md={6}>
            <div className="portfolio-content">
              <h3 className="portfolio-title">{trackApp[language]}</h3>
              <p>{trackAppDescription[language]}</p>
            </div>
          </Col>
        </Row>

        <Row className="portfolio-item">
          <Col md={6}>
            <div className="portfolio-content">
              <h3 className="portfolio-title">{posApp[language]}</h3>
              <p>{posAppDescription[language]}</p>
              <div className="text-right">
                <a href={fposUrl[language]}>
                  <Button
                    variant="success"
                    size="lg"
                    style={{ padding: "5px 20px", margin: "10px 0" }}
                  >
                    {showDetail[language]}
                  </Button>
                </a>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <img src={posImage} alt="Project 2" className="img-fluid" />
          </Col>
        </Row>

        <Row className="portfolio-item">
          <Col md={6}>
            <img src={attendanceImage} alt="Project 3" className="img-fluid" />
          </Col>
          <Col md={6}>
            <div className="portfolio-content">
              <h3 className="portfolio-title">{attendanceApp[language]}</h3>
              <p>{attendanceAppDescription[language]}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default PortfolioSection;
